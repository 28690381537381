@import "common.mix.less";
@body: #222;
@link: #555;
@em: #789922;
@del: #222;
@postBG: #fff;
@border: 1px solid #ddd;
@bold: #117743;
@admin: #f00000;
@mod: purple;
@red: @admin;
@blue: #1048ff;

a:hover, blockquote a {
	color: #0274be;
}

article, aside {
	background-color: @postBG;
	box-shadow: 0px 0px 1px 0px rgba(50, 50, 50, 0.1);
}

article {
	article {
		border: @border;
		box-shadow: none;
	}
	&.highlight:not(.editing), &:target {
		background-color: #FDE2E2;
		article {
			border: solid 1px #FFDBDB;
			box-shadow: none;
		}
	}
	&.editing {
		background-color: #D8EBCE;
		article {
			border: solid 1px #DEF1BF;
			box-shadow: none;
		}
	}
}

body {
	background-color: #eee;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 10pt;
}

h1 {
	color: @body;
	font: bolder 28px Tahoma,sans-serif;
	letter-spacing: -2px;
}

h2 {
	color: @body;
	font-family: Tahoma,sans-serif;
}

h3 {
	color: @body;
}

header nav a {
	color: @body;
}

hr, .modal hr {
	border-top: @border;
}

.popup-menu li:hover {
	color: #fff;
	background-color: @body;
}

.modal, .preview:not(.editing), .popup-menu, .reply-form {
	background-color: #fff;
	box-shadow: 0px 2px 1px 0px rgba(50, 50, 50, 0.2);
	border: none;
}

a.tab-sel {
	color: @bold;
}

.omit {
	color: #aaa;
}



#banner {
	background-color: @body;
	border:none;
	box-shadow:none;
}

#board-navigation, b#thread-post-counters {
	color: #777;
}

#banner a {
	color: #fff;
}

.fileinfo {
	margin-left: 0ex;
}

#threads:not(.custom-BG) section > article.op {
	box-shadow:none;
}

b#sync {
	color: #DEF1BF;
}

b#sync-counter {
	color: #FFDBDB;
}

.embed {
	border: 1px solid rgba(0, 0, 0, 0.1);
	color: @body;
}

.banner-float.svg-link  svg {
	padding: .05em 0 0.05em 0;
}


/* likes addon */
.likes button {
    border: 0;
    background: 0 0;
    text-shadow: none;
    font-weight: bold;
}
.like {
    color: #41ac00;
}
.dislike {
    color: #ce8700;
}

.likes button span {
    border-radius: 5px;
    padding: 0 3px;
    text-shadow: none;
    font-weight: normal;
}
